export default {
  Name: 'ARDI',
  Header: {
    TitleStep1: 'Boka ditt spel',
    TitleStep2: 'Nästan där',
    TitleStep3: 'Tack för din bokning!',
    TitleStep4: 'Hoppsan...',
  },
  Success: {
    text: `Om du inte har fått bokningsbekräftelse rekommenderar vi att kontrollera din skräppost.<br><br>Om du valde betala med fakturan, skickar vi bokningsbekräftelse under dagen.<br><br>Gör dig redo för ett äventyr!`,
    btn1: 'Boka ett annat spel',
    btn2: 'Tillbaka till startsidan',
  },
  Failed: {
    text: `Vi kunde inte genomföra din betalning.<br>Var god försök igen!`,
    btn1: 'Försök igen',
    btn2: 'Tillbaka till startsidan',
  },
  Language: 'Språk',
  ChooseLanguage: 'Välj språk',
  Date: 'Datum',
  Game: 'Spel',
  ChooseAGame: 'Välj spel',
  WhenDoYouWantToPlay: 'När vill du spela?',
  AndWhichTime: 'Och vilken tid?',
  HowManyAreYou: 'Hur många är ni?',
  AvailableImmediately: 'Omedelbart tillgänglig',
  Teams: 'Antal lag',
  NumberOfPeople: 'Antal personer',
  HowManyAreYouNote:
    'OBS! Optimal antal spelare per lag är {min}-{max}. Är ni fler kommer vi dela upp er i olika lag.',
  Choose: 'Välj',
  ChooseCategory: 'Välj kategori',
  GameType: 'Kategori',
  Episode: 'Episod',
  Price: 'Pris',
  InclVat: 'Inkl. moms',
  ThereShouldBeAtLeastNPlayers: 'Det borde finnas minst {min} spelare.',
  TheNumberOfPeopleExceeds:
    'Antalet personer överstiger det maximala antalet deltagare i ett lag, så antalet lag kommer att vara: {count}',
  PhoneMaxLength: 'Telefonnummer få ha högst {max} siffror',
  Next: 'Nästa',
  Voucher: 'Voucher',
  ByClickingTheConfirmButton:
    'Genom att klicka på "Bekräfta" godkänner du ARDI',
  TermsOfService: 'Användarvillkor',
  PrivacyPolicy: 'Integritetspolicy',
  And: 'och',
  Form: {
    Fields: {
      FullName: 'Fullständiga namn',
      FirstName: 'Namn',
      SecondName: 'Efternamn',
      Email: 'E-post',
      Phone: 'Telefonnummer',
      Discount: 'Rabattkod',
      ChoosePaymentMethod: 'Välj betalningsmetod',
      Invoice: 'Faktura (endast företag)',
      CardPayment: 'Kortbetalning',
      OrganisationNumber: 'Organisationsnummer',
      InvoiceEmail: 'Faktura E-post',
      CompanyName: 'Företagsnamn',
      Address: 'Adress',
      PostCode: 'Postnummer',
      City: 'Ort',
      ContactPerson: 'Referensperson',
      OtherInformation: 'Övrig information',
      IAmInterestedInReceivingInformation:
        'Jag är intresserad av att få information, rabatter och uppdateringar via e-post',
      BookingInformation: 'Bokningsinformation',
    },
    Active: 'Aktivera',
    Change: 'Ändra',
    Back: 'Tillbaka',
    Confirm: 'Bekräfta',
    Validation: {
      Required: 'Fältet är obligatoriskt',
      Email: 'Enter a valid email address',
      EnterValidFormat: 'Ange giltigt format',
      EnterValidNumbers: 'Ange giltiga nummer',
      EnterAValidPhoneNumber: 'Ange ett giltigt telefonnummer',
      PleaseFillTheFormCorrectly: 'Fyll i formuläret korrekt.',
      Latin: 'Endast latinska tecken är tillåtna',
    },
  },
  MeetingPoint: 'Startpunkt',
  PromoCodeWrong: 'Kampanjkoden är felaktig',
  link: {
    terms: "/villkor",
    privacy: "/integritetspolicy",
  },
};
